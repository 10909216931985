import { createSlice } from "@reduxjs/toolkit"

const layout = createSlice({
  name: "layout",
  initialState: { drawerOpen: false },
  reducers: {
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen
    },
  },
})

export const { toggleDrawer } = layout.actions

export default layout.reducer
