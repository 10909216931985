import { AsyncThunkAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as api from "api"
import { setSelectedAccessories } from "features/accessories/accessoriesSlice"
import { setSelectedColor } from "features/color/colorSlice"
import {
  fetchDetailings,
  setSelectedDetailing
} from "features/detailing/detailingSlice"
import { setSelectedUpholstery } from "features/upholstery/upholsterySlice"
import { createAsyncAppThunk, ThunkApiConfig } from "store"
import { buildQueryString, getQueryParams } from "./queryParams"

const name = "wizard"

export type WizardState = {
  model: api.models.Model | null
  price: api.Price | null
  licensingFee: api.prices.LicensingFeeResponse | null
  initialized: boolean
}

const initialState: WizardState = {
  model: null,
  price: null,
  licensingFee: null,
  initialized: false,
}

export const fetchModel = createAsyncAppThunk(
  `${name}/fetchModel`,
  async (modelId: string, { dispatch }) => {
    const { data } = await api.models.getModel(modelId)

    await dispatch(fetchDetailings({ modelId }))

    return data.body.details
  }
)

export const updatePriceAndURL = createAsyncAppThunk(
  `${name}/updatePriceAndURL`,
  async (_, { getState }) => {
    const {
      detailing: { selectedDetailingCode: detailing },
      color: { selectedColorCode: color },
      upholstery: { selectedUpholsteryCode: upholstery },
      accessories: { selectedAccessoriesCodes: accessories },
    } = getState()

    // Cancel the previous request
    api.prices.cancelGetPricesRequest?.()

    const { data } = await api.prices.getPrices(
      detailing,
      color,
      upholstery,
      accessories
    )

    if (typeof window !== "undefined") {
      // Build URL params
      const values = { detailing, color, upholstery, accessories }
      const params = buildQueryString(values)

      // Replace URL and preserve hash
      const url = `?${params}${location.hash}`
      history.replaceState(null, document.title, url)
    }

    return data.body
  }
)

export const updateLicensingFee = createAsyncAppThunk(
  `${name}/updateLicensingFee`,
  async (code: string) => {
    const { data } = await api.prices.getLicensingFee(code)
    return data.body
  }
)

export const initSelectedComponentsFromParams = createAsyncAppThunk(
  `${name}/initSelectedComponentsFromParams`,
  async (modelId: string, { getState, dispatch }) => {
    const params = getQueryParams()

    const firstDetailingCode = getState().detailing.detailings[0].details.code

    // Prepare the `setSelected*` actions for each of the provided params
    const actions: Array<AsyncThunkAction<any, any, ThunkApiConfig> | null> = [
      // Re-fetch the detailings in case there's a discount for authenticated users
      fetchDetailings({ modelId }),

      setSelectedDetailing(params.detailing || firstDetailingCode),
      params.color ? setSelectedColor(params.color) : null,
      params.upholstery ? setSelectedUpholstery(params.upholstery) : null,
      params.accessories && params.accessories.length > 0
        ? setSelectedAccessories(params.accessories)
        : null,
    ]

    await Promise.allSettled(
      actions.filter((action) => action !== null).map(dispatch)
    )
  }
)

const wizard = createSlice({
  name,
  initialState,
  reducers: {
    setPrice(state, { payload }: PayloadAction<WizardState["price"]>) {
      state.price = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModel.fulfilled, (state, { payload }) => {
        state.model = payload
      })
      .addCase(updatePriceAndURL.fulfilled, (state, { payload }) => {
        state.price = payload
      })
      .addCase(updateLicensingFee.fulfilled, (state, { payload }) => {
        state.licensingFee = payload
      })
      .addCase(initSelectedComponentsFromParams.fulfilled, (state) => {
        state.initialized = true
      })
  },
})

export const { setPrice } = wizard.actions

export default wizard.reducer
