import axios, { AxiosPromise, AxiosRequestConfig } from "axios"
import { Cache, cacheAdapterEnhancer } from "axios-extensions"

const API_URL = process.env.API_URL
if (!API_URL) {
  throw new Error("Please provide the API_URL environment variable.")
}

const config: AxiosRequestConfig = {
  baseURL: API_URL,
}

export const cache = new Cache<string, AxiosPromise>()

if (axios.defaults.adapter) {
  config.adapter = cacheAdapterEnhancer(axios.defaults.adapter, {
    defaultCache: cache,
  })
}

const instance = axios.create(config)

export default instance

export const CancelToken = axios.CancelToken

export const setToken = (token: string) =>
  (instance.defaults.headers["Authorization"] = `Bearer ${token}`)

export const clearToken = () =>
  delete instance.defaults.headers["Authorization"]
