import { createSlice } from "@reduxjs/toolkit"
import * as api from "api"
import { updatePriceAndURL } from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"

const name = "accessories"

export type AccessoriesState = {
  accessories: api.accessories.AccessoriesResponse
  selectedAccessoriesCodes: api.accessories.Accessory["code"][]
}

const initialState: AccessoriesState = {
  accessories: [],
  selectedAccessoriesCodes: [],
}

export const fetchAccessories = createAsyncAppThunk(
  `${name}/fetchAccessories`,
  async (detailingCode: string, { getState, dispatch }) => {
    const {
      data: { body: accessories },
    } = await api.accessories.getAccessories(detailingCode)

    const { selectedAccessoriesCodes } = getState().accessories

    // Re-select all the currently selected accessories which also exist in the
    // new set of accessories & all the free accessories
    const accessoriesToSelect = accessories
      .filter(
        (a) =>
          selectedAccessoriesCodes.includes(a.details.code) ||
          a.price.totalPrice === 0
      )
      .map((accessory) => accessory.details.code)
    dispatch(setSelectedAccessories(accessoriesToSelect))

    return accessories
  }
)

export const setSelectedAccessories = createAsyncAppThunk(
  `${name}/setSelectedAccessories`,
  (
    _accessoriesCodes: AccessoriesState["selectedAccessoriesCodes"],
    { dispatch }
  ) => {
    dispatch(updatePriceAndURL())
  }
)

const accessories = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessories.fulfilled, (state, { payload }) => {
        state.accessories = payload
      })
      .addCase(setSelectedAccessories.pending, (state, { meta }) => {
        state.selectedAccessoriesCodes = meta.arg
      })
  },
})

export default accessories.reducer
