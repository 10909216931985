import { createSlice } from "@reduxjs/toolkit"
import * as api from "api"
import { fetchAccessories } from "features/accessories/accessoriesSlice"
import { fetchColors } from "features/color/colorSlice"
import {
  updateLicensingFee,
  updatePriceAndURL,
} from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"

const name = "detailing"

export type DetailingState = {
  detailings: api.detailings.DetailingsResponse
  selectedDetailingCode: api.detailings.Detailing["code"]
}

const initialState: DetailingState = {
  detailings: [],
  selectedDetailingCode: "",
}

type fetchDetailingArg = { modelId: string; reselectCurrent?: boolean }
export const fetchDetailings = createAsyncAppThunk(
  `${name}/fetchDetailings`,
  async (
    { modelId, reselectCurrent }: fetchDetailingArg,
    { dispatch, getState }
  ) => {
    const {
      data: { body: detailings },
    } = await api.detailings.getDetailings(modelId)

    if (reselectCurrent) {
      // Reselect the currently selected detailing to fetch all the required data
      const detailingToSelect =
        getState().detailing.selectedDetailingCode || detailings[0].details.code
      await dispatch(setSelectedDetailing(detailingToSelect))
    }

    return detailings
  }
)

export const setSelectedDetailing = createAsyncAppThunk(
  `${name}/setSelectedDetailing`,
  async (detailingCode: string, { getState, dispatch }) => {
    const { detailings } = getState().detailing

    // If provided detailing doesn't exist, then we select the first one
    if (
      detailings.length > 0 &&
      !detailings.some((d) => d.details.code === detailingCode)
    ) {
      await dispatch(setSelectedDetailing(detailings[0].details.code))
      return
    }

    await Promise.all([
      dispatch(updatePriceAndURL()),
      dispatch(fetchColors(detailingCode)),
      dispatch(fetchAccessories(detailingCode)),
      dispatch(updateLicensingFee(detailingCode)),
    ])
  }
)

const detailing = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetailings.fulfilled, (state, { payload }) => {
        state.detailings = payload
      })
      .addCase(setSelectedDetailing.pending, (state, { meta }) => {
        state.selectedDetailingCode = meta.arg
      })
  },
})

export default detailing.reducer
