import { CarComponent, Image } from "api"
import axios from "./axios"

export type AccessoriesResponse = CarComponent<Accessory>[]

export interface Accessory {
  code: string
  name: string
  description: string
  image: Image | null
}

export const getAccessories = (detailingCode: string) =>
  axios.get<AccessoriesResponse>("/api/extras", {
    params: { finishingLevelCode: detailingCode },
  })
