import { createSlice } from "@reduxjs/toolkit"
import * as api from "api"
import { updatePriceAndURL } from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"

const name = "upholstery"

export type UpholsteryState = {
  upholsteries: api.upholsteries.UpholsteriesResponse
  selectedUpholsteryCode: api.upholsteries.Upholstery["code"]
}

const initialState: UpholsteryState = {
  upholsteries: [],
  selectedUpholsteryCode: "",
}

export const fetchUpholsteries = createAsyncAppThunk(
  `${name}/fetchUpholsteries`,
  async (
    { detailingCode, colorCode }: { detailingCode: string; colorCode: string },
    { dispatch, getState }
  ) => {
    const {
      data: { body: upholsteries },
    } = await api.upholsteries.getUpholsteries(detailingCode, colorCode)

    const selectedUpholstery = getState().upholstery.selectedUpholsteryCode
    if (selectedUpholstery) {
      // If the selected upholstery doesn't exist anymore in the new set of
      // upholsteries, reset the selected upholstery
      if (!upholsteries.some((u) => u.details.code === selectedUpholstery)) {
        dispatch(setSelectedUpholstery(""))
      }
    }

    return upholsteries
  }
)

export const setSelectedUpholstery = createAsyncAppThunk(
  `${name}/setSelectedUpholstery`,
  (
    _upholsteryCode: UpholsteryState["selectedUpholsteryCode"],
    { dispatch }
  ) => {
    dispatch(updatePriceAndURL())
  }
)

export const resetUpholsteries = createAsyncAppThunk(
  `${name}/resetUpholsteries`,
  (_, { dispatch }) => {
    dispatch(setSelectedUpholstery(""))
  }
)

const upholstery = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpholsteries.fulfilled, (state, { payload }) => {
        state.upholsteries = payload
      })
      .addCase(setSelectedUpholstery.pending, (state, { meta }) => {
        state.selectedUpholsteryCode = meta.arg
      })
      .addCase(resetUpholsteries.pending, (state) => {
        state.upholsteries = []
      })
  },
})

export default upholstery.reducer
