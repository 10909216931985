import { CarComponent, Image } from "api"
import axios from "./axios"

export type DetailingsResponse = CarComponent<Detailing>[]

export interface Detailing {
  code: string
  name: string
  image: Image
  engineName: string
  engineHorsePower: string
  gear: string
  mainFeatures: string[]
  categories: Category[]
  modelName: string
  pollutionLevel: number
  safetyLevel: number
  pollutionImage: Image
  safetyImage: Image
  tireSpecImage: Image
}

export interface Category {
  name: string
  items: Item[]
}

export interface Item {
  name: string
  description: string
}

export const getDetailing = (code: string) =>
  axios.get<Detailing>(`/api/finishingLevels/${code}`)

export const getDetailings = (modelId: string) =>
  axios.get<DetailingsResponse>("/api/finishingLevels", {
    params: { modelId },
  })
