const key = "orderId"

const ttl = 1800000 // 30min

type Order = {
  id: string
  expiresAt: number
}

export const getOrderId = (clearIfExpired = true) => {
  const orderJSON = localStorage.getItem(key)
  if (!orderJSON) return null

  const order = JSON.parse(orderJSON) as Order

  if (!clearIfExpired) return order.id

  if (!order || new Date().getTime() > order.expiresAt) {
    // If the item is expired, delete the item from storage and return null
    clearOrderId()
    return null
  }

  return order.id
}

export const storeOrderId = (orderId: string) =>
  localStorage.setItem(
    key,
    JSON.stringify({
      id: orderId,
      expiresAt: new Date().getTime() + ttl,
    })
  )

export const clearOrderId = () => localStorage.removeItem(key)
