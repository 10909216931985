import { Price } from "api"
import { Canceler } from "axios"
import qs from "qs"
import axios, { CancelToken } from "./axios"

export let cancelGetPricesRequest: Canceler | undefined

export const getPrices = (
  detailingCode: string,
  colorCode: string,
  upholsteryCode: string,
  accessoriesCodes: string[]
) =>
  axios.get<Price>("/api/prices", {
    params: {
      finishingLevelCode: detailingCode,
      colorCode,
      upholsteryCode,
      carExtraCodes: accessoriesCodes,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
    cancelToken: new CancelToken((c) => {
      cancelGetPricesRequest = c
    }),
    cache: false,
  })

export interface LicensingFeeResponse extends Price {
  annualPriceCatalogText: string
}

export const getLicensingFee = (code: string) =>
  axios.get<LicensingFeeResponse>(
    `/api/prices/licensingFee?finishingLevelCode=${code}`
  )
