import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as api from "api"
import { AxiosError } from "axios"
import { trackLoggedIn } from "common/analytics"
import { fetchDetailings } from "features/detailing/detailingSlice"
import * as orderRepository from "features/order/orderRepository"
import { createAsyncAppThunk } from "store"
import * as authRepository from "./authRepository"

const name = "auth"

export type AuthState = {
  open: boolean
  step: "Phone" | "OTP" | "Register"
  userInfo: api.auth.UserInfo | null
  temporaryToken: api.auth.VerifyUserResponse["verificationToken"]
  temporaryPhone: api.auth.VerifyUserResponse["verifiedMobilePhone"]
  error?: string
  loading: boolean
  authenticated: boolean
  initialized: boolean
  allowSendingCodeAgain: boolean
  codeExpirationInSeconds: number | null
  clientLoggedIn: boolean
}

const initialState: AuthState = {
  open: false,
  step: "Phone",
  userInfo: null,
  temporaryToken: "",
  temporaryPhone: "",
  error: "",
  loading: false,
  authenticated: false,
  initialized: false,
  allowSendingCodeAgain: false,
  codeExpirationInSeconds: null,
  clientLoggedIn: false,
}

const clearToken = () => {
  api.clearToken()
  authRepository.clearToken()
}

export const setUserInfo = createAsyncAppThunk(
  `${name}/setUserInfo`,
  async (_userInfo: AuthState["userInfo"], { getState, dispatch }) => {
    const {
      wizard: { model },
      auth: { initialized },
    } = getState()

    api.cache.reset()

    if (model && initialized) {
      // Re-fetch detailings to have updated prices for everything
      const modelId = model.id.toString()
      await dispatch(fetchDetailings({ modelId, reselectCurrent: true }))
    }
  }
)

export const generateVerificationCode = createAsyncAppThunk(
  `${name}/generateVerificationCode`,
  async (phone: string | undefined, { getState }) => {
    const { data } = await api.auth.generateVerificationCode(
      phone ?? getState().auth.temporaryPhone
    )
    return data.body.totalTimeoutInSeconds
  }
)

export const verifyUser = createAsyncAppThunk(
  `${name}/verifyUser`,
  async (
    verificationCode: api.auth.VerifyUserRequest["verificationCode"],
    { getState, rejectWithValue, dispatch }
  ) => {
    const { data } = await api.auth.verifyUser({
      mobilePhone: getState().auth.temporaryPhone,
      verificationCode,
    })

    if (data.errorCode !== 0) {
      return rejectWithValue({
        code: data.errorCode,
        message: data.errorMessage ?? "",
      })
    }

    if (data.body.userInfo) {
      // remove saved order id
      orderRepository.clearOrderId()

      trackLoggedIn()

      dispatch(setClientLoggedIn(true))

      await dispatch(setUserInfo(data.body.userInfo))
    }

    return data.body
  }
)

export const register = createAsyncAppThunk(
  `${name}/register`,
  async (
    {
      fullName,
      showRoom,
      agreeMarketingOffers,
    }: { fullName: string; showRoom: number; agreeMarketingOffers: boolean },
    { getState, dispatch }
  ) => {
    const { temporaryPhone, temporaryToken } = getState().auth

    const { data } = await api.auth.register({
      mobilePhone: temporaryPhone,
      fullName,
      showRoom,
      verifyToken: temporaryToken,
      isMailing: agreeMarketingOffers,
    })

    // remove saved order id
    orderRepository.clearOrderId()

    trackLoggedIn()

    dispatch(setClientLoggedIn(true))

    await dispatch(setUserInfo(data.body.userInfo))
  }
)

export const logout = createAsyncAppThunk(
  `${name}/logout`,
  async (_, { dispatch }) => {
    authRepository.resetHideCTA()

    // remove saved order id
    orderRepository.clearOrderId()

    await Promise.allSettled([api.auth.logout(), dispatch(setUserInfo(null))])
  }
)

export const initAuth = createAsyncAppThunk(
  `${name}/initAuth`,
  async (token: string | null, { dispatch }) => {
    if (!token) return

    api.setToken(token)

    try {
      const { data } = await api.auth.getUserInfo()
      await dispatch(setUserInfo(data.body))
    } catch (error) {
      const { response } = error as AxiosError
      if (response?.status === 401) clearToken()
    }
  }
)

const auth = createSlice({
  name,
  initialState,
  reducers: {
    setOpen(state, { payload }: PayloadAction<AuthState["open"]>) {
      state.open = payload
    },
    backToPhoneStep(state) {
      state.step = "Phone"
    },
    setAllowSendingCodeAgain(state) {
      state.allowSendingCodeAgain = true
    },
    setClientLoggedIn(
      state,
      { payload }: PayloadAction<AuthState["clientLoggedIn"]>
    ) {
      state.clientLoggedIn = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserInfo.pending, (state, { meta }) => {
        const userInfo = meta.arg

        if (userInfo) {
          if (userInfo.accessToken) {
            api.setToken(userInfo.accessToken)
            authRepository.setToken(userInfo.accessToken)
          }

          state.authenticated = true
          state.temporaryPhone = ""
        } else {
          clearToken()

          state.authenticated = false
        }

        state.userInfo = userInfo
        state.open = false
        state.loading = false
      })
      .addCase(generateVerificationCode.pending, (state, { meta }) => {
        state.loading = true
        if (meta.arg) state.temporaryPhone = meta.arg
        state.allowSendingCodeAgain = false
        state.codeExpirationInSeconds = null
      })
      .addCase(generateVerificationCode.fulfilled, (state, { payload }) => {
        state.step = "OTP"
        state.loading = false
        state.codeExpirationInSeconds = payload
      })
      .addCase(verifyUser.pending, (state) => {
        state.loading = true
        state.error = ""
      })
      .addCase(verifyUser.fulfilled, (state, { payload }) => {
        if (!payload.userInfo) {
          state.temporaryToken = payload.verificationToken
          state.temporaryPhone = payload.verifiedMobilePhone
          state.step = "Register"
        }

        state.loading = false
      })
      .addCase(verifyUser.rejected, (state, { payload, error }) => {
        if (payload) {
          state.error = payload.code === 50001 ? "קוד שגוי" : payload.message
        } else {
          state.error = error.message
        }

        state.loading = false
      })
      .addCase(register.pending, (state) => {
        state.loading = true
      })
      .addCase(logout.fulfilled, (state) => {
        state.step = "Phone"
      })
      .addCase(initAuth.fulfilled, (state) => {
        state.initialized = true
      })
  },
})

export const {
  setOpen,
  backToPhoneStep,
  setAllowSendingCodeAgain,
  setClientLoggedIn,
} = auth.actions

export default auth.reducer
