import { createSlice } from "@reduxjs/toolkit"
import * as api from "api"
import * as orderRepository from "features/order/orderRepository"
import { createAsyncAppThunk } from "store"

const name = "payment"

type PaymentState = {
  creditGuardUrl: string
  transactionId: string
  transactionComplete: api.payment.CompleteOrderResponse | null
}

export const initialState: PaymentState = {
  creditGuardUrl: "",
  transactionComplete: null,
  transactionId: "",
}

export const fetchPaymentUrl = createAsyncAppThunk(
  `${name}/fetchPaymentUrl`,
  async (orderId: string) => {
    const { data } = await api.payment.getCreditGuardUrl(orderId)
    return data.body
  }
)

export const setTransaction = createAsyncAppThunk(
  `${name}/setTransaction`,
  async (transaction: api.payment.TransactionParams, { getState }) => {
    if (!transaction) return null

    const {
      payment: { transactionId },
    } = getState()

    const orderId = orderRepository.getOrderId(false) as string

    const {
      data: { body: response },
    } = await api.payment.completeOrder(transaction, orderId, transactionId)

    response.message = transaction.ErrorText || response.message

    return response
  }
)

const payment = createSlice({
  name,
  initialState,
  reducers: {
    clearCreditGuardUrl(state) {
      state.creditGuardUrl = ""
    },
    resetTransactionComplete(state) {
      state.transactionComplete = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentUrl.fulfilled, (state, { payload }) => {
        state.creditGuardUrl = payload.mpiHostPageUrl
        state.transactionId = payload.transactionId
      })
      .addCase(setTransaction.fulfilled, (state, { payload }) => {
        if (!payload) return
        state.transactionComplete = payload
      })
  },
})

export default payment.reducer

export const {
  actions: { clearCreditGuardUrl, resetTransactionComplete },
} = payment
