import axios from "./axios"

interface GenerateVerificationCodeResponse {
  totalTimeoutInSeconds: number
}

export const generateVerificationCode = (mobilePhone: string) =>
  axios.get<GenerateVerificationCodeResponse>(
    "/api/account/generateVerificationCode",
    {
      params: { mobilePhone },
      cache: false,
    }
  )

export interface RegisterRequest {
  fullName: string
  mobilePhone: string
  verifyToken: string
  showRoom: number
  isMailing: boolean
}

export interface RegisterResponse {
  userInfo: UserInfo
  message: string
}

export interface UserInfo {
  id: number
  accessToken: string
  dateCreated: Date
  showRoom: number
  fullName: string
  emailAddress: string
  mobilePhone: string
  birthDay: Date
}

export const register = (data: RegisterRequest) =>
  axios.post<RegisterResponse>("/api/account/register", data)

export interface VerifyUserRequest {
  mobilePhone: string
  verificationCode: string
}

export interface VerifyUserResponse {
  userInfo: UserInfo | null
  verifiedMobilePhone: string
  verificationToken: string
}

export const verifyUser = (data: VerifyUserRequest) =>
  axios.post<VerifyUserResponse>("/api/account/verifyUser", data)

export const logout = () => axios.post<boolean>("/api/account/logout")

export const getUserInfo = () =>
  axios.get<UserInfo>("/api/account/getUserInfo", { cache: false })
