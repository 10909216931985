import axios from "./axios"

export interface RedirectTransactionResponse {
  transactionId: string
  mpiHostPageUrl: string
  message: string
}

export interface TransactionParams {
  succeed: string
  uniqueID: string
  lang: string
  authNumber: string
  cgUid: string
  responseMac: string
  cardToken: string
  cardExp: string
  personalId: string
  cardMask: string
  txId: string
  numberOfPayments: string
  firstPayment: string
  periodicalPayment: string
  ErrorCode: string
  ErrorText: string
}

export interface CompleteOrderResponse {
  transactionId: string
  orderId: number
  succeed: boolean
  errorCode: string
  message: string
}

export const getCreditGuardUrl = (orderId: string) =>
  axios.post<RedirectTransactionResponse>("/api/payment/redirectTransaction", {
    orderId,
    redirectTransactionRequest: {
      userData1: "",
      userData2: "",
      userData3: "",
    },
  })

export const completeOrder = (
  params: TransactionParams,
  orderId: string,
  transactionId: string
) =>
  axios.post<CompleteOrderResponse>("/api/orders/complete", {
    orderId,
    transactionId,
    txId: params.txId,
    errorCode: params.ErrorCode,
    cardToken: params.cardToken,
    cardExp: params.cardExp,
    personalId: params.personalId,
    uniqueId: params.uniqueID,
    responseMac: params.responseMac,
    paymentSucceed: params.succeed,
  })
