import { CarComponent, Image, Price } from "api"
import axios from "./axios"

export type ModelResponse = CarComponent<Model>

export type ModelsResponse = ModelResponse[]

export interface Model {
  id: number
  name: string
  image: Image
  type: number
  announcement: string
  lowestFinishingLevelLicensingFee: Price
}

export const getModel = (id: string) =>
  axios.get<ModelResponse>(`/api/models/${id}`)

export const getModels = () => axios.get<ModelsResponse>("/api/models")
