import axios from "./axios"

export interface Contact {
  fullName: string
  phone: string
  showRoom: number
  modelId: number | null
  platform: "Pc" | "Mobile" | null
}

export interface ContactRequest extends Contact {
  branchId: string
  isMailing: boolean
}

export const sendContact = (data: ContactRequest) =>
  axios.post<boolean>("/api/contact", data)
