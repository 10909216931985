import axios from "./axios"

export type ShowRoomsResponse = ShowRoom[]

interface ShowRoom {
  id: number
  name: string
  address: string
  phone: string
  fax: string
  whatsapp: string
}

export const getShowRooms = () =>
  axios.get<ShowRoomsResponse>("/api/appMetaData/showrooms")
