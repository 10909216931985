import { CarComponent, Image } from "api"
import axios from "./axios"

export type ColorsResponse = CarComponent<Color>[]

export interface Color {
  images: Image[]
  code: string
  name: string
  hexValue: string
}

export const getColors = (detailingCode: string) =>
  axios.get<ColorsResponse>("/api/colors", {
    params: { finishingLevelCode: detailingCode },
  })
