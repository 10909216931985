import qs from "qs"

type ParamsValues = Partial<{
  detailing: string
  color: string
  upholstery: string
  accessories: string[]
}>

const paramsKeys = ["detailing", "color", "upholstery", "accessories"]

const getUtmParams = () => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as any

  return Object.keys(params)
    .filter((key) => !paramsKeys.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: params[key],
      }),
      {}
    )
}

export const buildQueryString = (values: ParamsValues) =>
  qs.stringify(
    { ...getUtmParams(), ...values },
    {
      arrayFormat: "repeat",

      // Filter-out empty values
      filter: (_, value) =>
        value === undefined || value === "" ? undefined : value,
    }
  )

export const getQueryParams = () =>
  qs.parse(location.search, { ignoreQueryPrefix: true }) as ParamsValues
