import { CarComponent, Image } from "api"
import axios from "./axios"

export type UpholsteriesResponse = CarComponent<Upholstery>[]

export interface Upholstery {
  images: Image[]
  code: string
  name: string
  description: string
  thumbnailImage: Image
}

export const getUpholsteries = (detailingCode: string, colorCode: string) =>
  axios.get<UpholsteriesResponse>("/api/upholsteries", {
    params: { finishingLevelCode: detailingCode, colorCode },
  })
