import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as api from "api"
import {
  fetchUpholsteries,
  resetUpholsteries,
} from "features/upholstery/upholsterySlice"
import { updatePriceAndURL } from "features/wizard/wizardSlice"
import { createAsyncAppThunk } from "store"

const name = "color"

export type ColorState = {
  colors: api.colors.ColorsResponse
  selectedColorCode: api.colors.Color["code"]
  loading: boolean
}

const initialState: ColorState = {
  colors: [],
  selectedColorCode: "",
  loading: true,
}

export const fetchColors = createAsyncAppThunk(
  `${name}/fetchColors`,
  async (detailingCode: string, { dispatch, getState }) => {
    const {
      data: { body: colors },
    } = await api.colors.getColors(detailingCode)

    const selected = getState().color.selectedColorCode
    if (selected) {
      // Check if the selected color still exists in the new set of colors
      if (colors.some((c) => c.details.code === selected)) {
        // If the selected color still exists, that means that the user has
        // selected a different detailing, so to have updated prices for the
        // upholsteries, we need to re-fetch them
        dispatch(fetchUpholsteries({ detailingCode, colorCode: selected }))
      } else {
        // If the selected color doesn't exists anymore, reset the selected color
        dispatch(setSelectedColor(""))
      }
    }

    return colors
  }
)

export const setSelectedColor = createAsyncAppThunk(
  `${name}/setSelectedColor`,
  (colorCode: string, { getState, dispatch }) => {
    dispatch(updatePriceAndURL())

    if (colorCode) {
      const { selectedDetailingCode: detailingCode } = getState().detailing
      dispatch(fetchUpholsteries({ detailingCode, colorCode }))
    } else {
      dispatch(resetUpholsteries())
    }
  }
)

const color = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<ColorState["loading"]>) {
      state.loading = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColors.fulfilled, (state, { payload }) => {
        state.colors = payload
      })
      .addCase(setSelectedColor.pending, (state, { meta }) => {
        state.selectedColorCode = meta.arg
      })
  },
})

export const { setLoading } = color.actions

export default color.reducer
