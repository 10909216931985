import axios from "./axios"

export type OrderModel = {
  selectedDetailingCode: string
  selectedColorCode: string
  selectedUpholsteryCode: string
  selectedAccessoriesCodes: string[]
}

export interface CreateOrderResponse {
  orderId: string
}

export interface UserInfo {
  showRoom: number
  fullName: string
  emailAddress: string
  mobilePhone: string
}
export interface UpdateOrderRequest extends OrderModel {
  userInfo: UserInfo
  orderId: string
}

export const createOrder = (model: OrderModel) =>
  axios.post<CreateOrderResponse>("/api/orders", {
    finishingLevelCode: model.selectedDetailingCode,
    colorCode: model.selectedColorCode,
    upholsteryCode: model.selectedUpholsteryCode,
    carExtraCodes: model.selectedAccessoriesCodes,
  })

export const updateOrder = (request: UpdateOrderRequest) => {
  return axios.put<CreateOrderResponse>("/api/orders", {
    finishingLevelCode: request.selectedDetailingCode,
    colorCode: request.selectedColorCode,
    upholsteryCode: request.selectedUpholsteryCode,
    extrasCodes: request.selectedAccessoriesCodes,
    orderId: request.orderId,
    userInfo: request.userInfo,
  })
}
